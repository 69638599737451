@charset "UTF-8";

.home {
  &.afiliado,
  &.no-afiliado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1,
    h2 {
      text-align: center;
      color: $medium-gray;
    }

    h1 {
      margin: 0;
      margin-bottom: 40px;
      padding: 0;
      font-size: 36px;
    }

    h2 {
      margin: 0;
      margin-bottom: 40px;
      padding: 0;
      font-size: 24px;
    }

    .button {
      min-width: 300px;
      margin-bottom: 100px;
      font-size: 18px;
    }

    .MuiSkeleton-root {
      width: 450px;
      height: 250px;
      margin: -30px 0;
    }
  }

  @media (max-width: 768px) {
    &.afiliado,
    &.no-afiliado {
      h1 {
        margin-bottom: 20px;
        font-size: 28px;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 18px;
      }

      .button {
        min-width: 70vw;
        margin-bottom: 80px;
        font-size: 14px;
      }

      .MuiSkeleton-root {
        width: 90%;
        height: 200px;
        margin: -30px 0;
      }
    }
  }
}
