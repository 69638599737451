@charset "UTF-8";

$yellow: #fec600;
$red: #fe1600;
$blue: #3f51b5;
$green: rgb(35, 196, 43);

$primary: #25aae1;
$secondary: #8dc63f;
$light-secondary: #c6e995;

$lighter-gray: rgb(240, 240, 240);
$light-gray: rgb(210, 210, 210);
$medium-gray: rgb(160, 160, 160);
$gray: rgb(100, 100, 100);
$dark-gray: rgb(50, 50, 50);
