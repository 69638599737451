@charset "UTF-8";

.nuevo-reembolso {
  h3 {
    margin-bottom: 15px;
    padding-bottom: 7px;
  }

  .buttons {
    margin-top: 20px;

    .button {
      min-width: 300px;
      min-height: 60px;
    }
  }

  .sections {
    display: flex;
    justify-content: space-evenly;

    .section {
      display: flex;

      .documents {
        display: flex;
        flex-direction: column;
        min-width: 400px;

        .descripciones {
          display: flex;
          flex-direction: column;
          border-radius: 3px;
          padding: 20px;

          .descripcion {
            min-width: 250px;
            margin-bottom: 20px;

            input {
              color: $gray;
            }
          }
        }

        .documents-list {
          padding: 0px 20px;
          margin-bottom: 20px;

          .document-item,
          .add-document {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;

            .button {
              width: 100%;
              min-height: 0;
            }
          }

          .add-document {
            margin-bottom: 1.5rem;
          }

          .imagen {
            width: 80px;
            height: 80px;
            padding: 15px;
            border: 1px solid $light-gray;
            border-radius: 4px;
          }
        }
      }

      .opcion-pago {
        display: flex;
        flex-direction: column;
        min-width: 400px;

        .metodo {
          display: flex;
          justify-content: space-between;
          border: 1px solid $medium-gray;
          border-radius: 3px;
          padding: 20px;
          margin-bottom: 20px;

          .fields {
            margin-top: 20px;
            display: flex;
            flex-direction: column;

            .field {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }

  .comprobante {
    margin-bottom: 1em;
    margin: 0 auto;
    width: 50%;

    h3 {
      text-align: center;
    }

    .MuiSkeleton-root {
      margin: 0 auto;
      width: 80%;
      margin-bottom: -20px;
    }

    p {
      text-align: center;

      .monto {
        font-size: large;
        font-weight: 700;
      }
    }
  }

  @media (max-width: 768px) {
    .buttons {
      margin-top: 30px;

      .button {
        width: 100%;
      }
    }

    .sections {
      display: flex;
      flex-direction: column;

      .section {
        flex: 1;
        display: flex;
        padding: 0;
        justify-content: center;

        .documents {
          min-width: 0;
          flex: 1;

          .descripciones {
            margin-bottom: 20px;
            padding: 0;
          }
          .documents-list {
            padding: 0;
          }
        }
        .opcion-pago {
          min-width: 0;
          flex: 1;
        }
      }
    }

    .comprobante {
      width: 100%;

      .MuiSkeleton-root {
        margin: 0 auto;
        width: 90%;
        margin-bottom: -35px;
      }
    }
  }
}

.multipage {
  display: flex;
  flex-direction: row;
}

.mis-reembolsos {
  width: 55%;

  .reembolsos-list {
    display: flex;
    flex-direction: column;

    .category-list {
      .reembolso-avatar {
        width: 45px;
        height: 45px;
        background-color: $primary;
      }

      .MuiListItemText-primary {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80%;
      }

      .MuiListItemSecondaryAction-root {
        top: 35%;
      }
    }
  }
}

.detalle-reembolso {
  width: 45%;

  .card {
    margin-top: 30px;

    .card-header {
      font-weight: 700;
    }

    .card-body {
      padding: 30px 40px;
    }

    label {
      padding: 12px;
      margin: 0px;
      max-width: 100%;
    }

    .document-item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .file {
        margin-right: 1rem;
        text-transform: none !important;
      }
    }
  }
}

.fab-icons {
  transition: all 0.1s ease-in-out;
  position: fixed;
  right: 30px;
  bottom: 90px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .mis-reembolsos {
    width: 100%;
  }
  .detalle-reembolso {
    width: 100%;

    .card {
      margin-top: 10px;

      .card-header {
        display: flex;
      }

      label {
        max-width: 80%;
      }
    }
  }
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);
  width: 70vw;
  margin: 0 auto;

  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.35rem;

    .text {
      font-size: 2rem;

      @media only screen and (max-width: 600px) {
        font-size: 1.5rem;
      }
    }

    .emoji {
      font-size: 30px;
      vertical-align: middle;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100vw;
    padding: 24px;
  }
}
