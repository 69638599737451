@charset "UTF-8";

@import url("https://fonts.googleapis.com/css?family=Montserrat:400&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  background: #fefefe;
  font-family: "Montserrat", sans-serif;
  color: $gray;
}

html {
  scroll-behavior: smooth;
}

button {
  outline: none !important;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 900;

  .logo {
    margin: 10px 30px;
    float: left;
    transition: 0.25s;

    img {
      height: 100px;
      transition: 0.25s;
    }
  }

  .menu {
    display: flex;
    margin: 15px 30px;
    float: right;
    justify-content: center;

    li {
      display: flex;
      justify-content: center;
    }

    .ingresa-btn {
      margin-left: 25px;
      min-width: 200px !important;
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px 10px;
  font-size: 0.8rem;
  line-height: 1.25rem;
  background: #fff;
  color: $gray;
  z-index: 900;

  &.app-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    border-top: 1px solid $light-gray;
  }

  .menu {
    display: flex;

    a,
    button {
      display: block;
      margin: 0;
      padding: 0 20px;
      border: none;
      border-right: 1px solid $gray;
      color: $gray;
      text-decoration: none;
      transition: 0.25s;
      background: none;
      outline: none;

      &:hover {
        color: $dark-gray;
      }

      &:last-child {
        border-right: none;
      }
    }
  }

  hr {
    margin: 15px auto;
  }

  .contacto {
    display: flex;

    .logo {
      min-width: 100px;

      &.caja {
        text-align: right;
      }

      &.sura {
        text-align: left;
      }

      img {
        height: 40px;
      }
    }

    p {
      margin: 0 30px;
      text-align: center;

      a {
        color: $gray;
        text-decoration: none;
      }
    }
  }
}

.field {
  .field-input {
    padding: 7px 14px;
    border: 1px solid $light-gray;
    color: $gray;
  }
}

.error-message,
.field-error {
  color: $red;
  font-size: small;
}

.input-error {
  background: rgb(255, 200, 200);
}

.button {
  display: inline-block;
  padding: 10px !important;
  border: none !important;
  font-size: 0.9rem;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  transition: 0.25s !important;

  &:hover {
    text-decoration: none !important;
  }

  &.secondary {
    background: $light-gray !important;
    color: $gray !important;

    &:hover {
      background: $lighter-gray !important;
      color: $gray !important;
    }
  }

  &.link {
    color: #fff !important;
  }
}

.top-bar {
  box-shadow: 0 5px 10px rgba(100, 100, 100, 0.2);

  .logo {
    margin: 15px 20px;

    img {
      height: 60px;
    }
  }

  .hamburger {
    display: block;
    width: 46px;
    height: 42px;
    margin: 23px 32px 5px 10px;
    float: right;
    cursor: pointer;
    text-align: center;
    font-size: 30px;
    color: $primary;

    &:before {
      content: "\f0c9";
      font-family: fontAwesome;
      line-height: 30px;
    }
  }

  .username {
    margin: 35px 30px 5px 10px;
    font-size: 18px;
    line-height: 18px;
    float: right;
  }
}

.side-bar {
  height: 100vh;
  width: 350px;
  position: fixed;
  top: 0;
  right: -350px;
  border-left: 1px solid $light-gray;
  background: #fff;
  box-shadow: -10px 0 20px rgba(100, 100, 100, 0.2);
  transition: right ease-in 0.25s;
  z-index: 901;

  &.active {
    right: 0;
    transition: right ease-out 0.35s;
  }

  .top-side-bar {
    height: 90px;
    background: $primary;

    .close-btn {
      display: block;
      width: 46px;
      height: 42px;
      margin: 23px 32px 5px 10px;
      float: right;
      text-align: center;
      font-size: 30px;
      color: #fff;
      border: none;
      background: none;
      outline: none;

      &:before {
        content: "\f00d";
        font-family: fontAwesome;
        line-height: 30px;
      }
    }
  }

  nav {
    .link {
      display: block;
      width: 100%;
      padding: 20px 30px;
      font-size: 20px;
      text-align: left;
      text-decoration: none;
      color: $gray;
      border: none;
      background: none;
      outline: none;
      transition: 0.25s;

      .text {
        margin-left: 10px;
      }

      &:hover {
        background: $light-secondary;
      }
    }
  }
}

.page {
  min-height: calc(100vh - 160px);
  margin-top: 90px;
  margin-bottom: 70px;
  padding: 50px;

  .steps {
    width: 100%;
    display: flex;
    margin: 0;
    padding: 40px;
    background: $lighter-gray;

    li {
      display: block;
      list-style-type: none;
      font-size: 20px;
      text-align: center;
      color: $light-gray;
      border-bottom: 1px solid $light-gray;
      transition: 0.2s;

      &.active {
        color: $gray;
        border-bottom-color: $gray;
      }
    }
  }

  .step {
    min-height: calc(100vh - 160px - 111px - 102px - 20px);
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .field {
      width: 300px;
      margin: 10px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    border-top: 1px solid $light-gray;

    .button {
      width: 100px;
      margin: 20px 10px;
    }
  }
}

.loading {
  height: 0vh;
  width: 0vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fefefe;
  opacity: 0;
  transition: opacity 0.25s;
  z-index: 899;

  &.show {
    height: 100vh;
    width: 100vw;
    opacity: 1;
  }

  p {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  header {
    min-height: 60px;
    background: #fff;
    box-shadow: 0 5px 7px rgba(100, 100, 100, 0.3);

    .logo {
      margin: 15px 20px;

      img {
        height: 30px;
      }
    }

    .hamburger {
      display: block;
      width: 30px !important;
      height: 30px !important;
      margin: 8px 25px 5px 10px !important;
      float: right;
      cursor: pointer;
      text-align: center;
      font-size: 30px;
      color: $dark-gray;

      &:before {
        content: "\f0c9";
        font-family: fontAwesome;
        line-height: 30px;
      }

      &.active:before {
        content: "\f00d";
      }
    }

    .menu {
      display: none !important;
      padding-top: 0px !important;

      &.active {
        width: calc(100vw - 30px) !important;
        margin: 15px !important;
        float: none !important;
        display: block !important;
        clear: both !important;
        text-align: center !important;
      }

      li {
        display: flex;
        justify-content: center;
      }

      .ingresa-btn {
        margin: 10px 0px;
        min-width: 175px !important;
        margin-left: 0px;
      }
    }
  }

  .top-bar {
    .logo {
      img {
        height: 30px !important;
      }
    }

    .username {
      display: none;
    }
  }

  .side-bar {
    max-width: 70vw;

    .top-side-bar {
      height: 60px;

      .close-btn {
        width: 30px !important;
        height: 30px !important;
        margin: 8px 25px 5px 10px !important;
      }
    }

    nav {
      .link {
        font-size: 16px;
      }
    }
  }

  .page {
    min-height: calc(100vh - 120px);
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 20px;

    .steps {
      padding: 20px;

      li {
        .label {
          display: none;
        }
      }
    }

    .step {
      min-height: calc(100vh - 120px - 71px - 92px - 20px);
      justify-content: flex-start;
    }

    .buttons {
      width: 100%;
      margin: 10px 0;
    }
  }

  footer {
    padding: 0;

    .menu {
      flex-direction: column;

      button {
        width: 100vw;
        padding: 12px 20px;
        border: none;
        border-bottom: 1px solid $light-gray;
      }
    }

    hr {
      display: none;
    }

    .contacto {
      margin: 10px auto;

      .logo {
        min-width: 40px;
      }
    }
  }
}
