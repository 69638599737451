@charset "UTF-8";

.perfil {
  &.editor {
    padding: 0;
  }
}

.ver-perfil {
  .top {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .datos {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;

    dl {
      margin: 0 30px;
    }

    .datos-editar-titulo {
      font-weight: bold;
    }

    .datos-editar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .field {
      width: 300px;
      margin: 10px 0;
    }

    .datos-buttons {
      display: flex;
      width: 100%;
      flex-direction: row;
      margin-top: 20px;
      justify-content: space-between;

      .button {
        font-size: 13px !important;
        min-width: 140px;
      }
    }

    .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }
  }
}

.wizard-perfil {
  padding: 0;

  .revision {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;

    .column {
      margin: 0 30px 30px 30px;
    }
  }

  .listo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10px;

    h1,
    h2 {
      color: $medium-gray;
      text-align: center;
    }

    h1 {
      margin: 0;
      margin-bottom: 40px;
      padding: 0;
      font-size: 36px;
    }

    h2 {
      margin: 0;
      margin-bottom: 40px;
      padding: 0;
      font-size: 24px;
    }

    .button {
      min-width: 300px;
      margin-bottom: 100px;
      font-size: 18px;
    }
  }
}

@media (max-width: 768px) {
  .ver-perfil {
    .top {
      margin-bottom: 10px;
      justify-content: space-between;
    }

    .datos {
      flex-direction: column;

      dl {
        margin: 20px 0;
      }

      .datos-editar {
        width: 100%;
      }

      .field {
        width: 100%;
        margin: 10px 0;
      }
    }
  }

  .editar-perfil {
    padding-top: 30px;

    .steps {
      display: none;
    }

    .revision {
      flex-direction: column;

      dl {
        margin: 20px 0;
      }
    }

    .listo {
      h1 {
        margin-bottom: 20px;
        font-size: 30px;
      }

      h2 {
        margin-bottom: 20px;
        font-size: 18px;
      }

      .button {
        min-width: 300px;
        margin-bottom: 50px;
        font-size: 16px;
      }
    }
  }
}
